@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
:root {
  --color-black: #000;
  --color-white: #fff;
}

.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "asd", sans-serif;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.container {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #383838;
  background: #F4F7FB;
}

p {
  margin: 0;
}

.header {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  background: #fff;
  height: 70px;
  padding: 0 15px;
  position: relative;
  z-index: 2;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header__inner .left__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header__inner .left__header .navbar ul {
  display: flex;
  list-style: none;
  padding-left: 61px;
}
.header__inner .left__header .navbar ul li {
  margin-left: 39px;
}
.header__inner .left__header .navbar ul li:first-child {
  margin-left: 0;
}
.header__inner .left__header .navbar ul li a {
  font-size: 16px;
  color: #989898;
  font-weight: 400;
}
.header__inner .left__header .navbar ul li a:hover {
  color: #171717;
}
.header__inner .right__header {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.header__inner .right__header .btn__enter a {
  color: #69AC8C;
  text-decoration: none;
  width: 92px;
  height: 34px;
  font-size: 15px;
  font-weight: 600;
  background: #D9FEEC;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
}
.header__inner .right__header .phone__number {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  margin-left: 24px;
  color: #383838;
}
.header__inner .right__header .phone__number img {
  margin-right: 4px;
}

section {
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

section.main {
  padding-top: 70px;
}

.font-exbold {
  font-weight: 800;
}

.selection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 31px;
  margin-top: 45px;
}
.selection__image {
  position: absolute;
  right: 8px;
  top: 50px;
  z-index: 1;
}
.selection__image:before {
  content: "";
  position: absolute;
  top: -20px;
  width: 256px;
  height: 243px;
  border: 20px solid #D2FAE7;
  right: -40px;
  border-radius: 50%;
  z-index: -1;
  box-sizing: border-box;
}
.selection__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px 23px;
  height: 334px;
}
.selection__item .image__parag p {
  color: #3D3D3D;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
}
.selection__item .image__parag p span {
  font-size: 22px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 8px;
}
.selection__item .select__btn a {
  color: #69AC8C;
  text-decoration: none;
  padding: 13px 19px;
  font-size: 15px;
  font-weight: 600;
  background: #D9FEEC;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
}
.selection__item p {
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #383838;
}

.main__title {
  font-size: 50px;
  line-height: 46px;
  font-weight: 700;
}

.sub__title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  margin-bottom: 35px;
}

.exchange__inner {
  border-radius: 10px;
  padding: 24px 17px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: grid;
  width: calc(100% + 15px);
  margin-left: -7px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 39px;
}
.exchange__inner-item:last-child {
  margin-right: 0;
}
.exchange__inner-item span {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  text-transform: uppercase;
  margin-right: 10px;
  color: #929292;
}
.exchange__inner-item span.valute {
  color: #929292;
}
.exchange__inner-item span.value {
  color: #383838;
}
.exchange__inner-item .arrow svg {
  margin-right: 4px;
}
.exchange__inner-item .arrow.true__arrow {
  color: #26B291;
}
.exchange__inner-item .arrow.true__arrow svg path {
  fill: #26B291;
}
.exchange__inner-item .arrow.false__arrow {
  color: #D01E48;
}
.exchange__inner-item .arrow.false__arrow svg {
  transform: rotate(-180deg);
}
.exchange__inner-item .arrow.false__arrow svg path {
  fill: #D01E48;
}

.inner__benefit {
  background: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  padding-bottom: 0;
  border-radius: 10px;
  margin-top: 23px;
  width: 100%;
  display: grid;
  /* Define Auto Row size */
  grid-auto-rows: 100px;
  /*Define our columns */
  grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
  grid-gap: 0 20px;
}
.inner__benefit-item {
  text-align: center;
}
.inner__benefit-item:last-child {
  margin-right: 0;
}
.inner__benefit-item .height__flex {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.btn__credit {
  margin: 25px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn__credit a {
  font-weight: 600;
  padding: 12px 30px;
  background: #52BA88;
  border-radius: 5px;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
}

.green__span {
  color: #52BA88;
}

.under__title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.btn__green {
  background: #52BA88;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  color: #fff;
  padding: 15px;
  border: none;
  outline: none;
}

.checkbox__all {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox__all input {
  margin-right: 10px;
  min-width: 18px;
  height: 18px;
}
.checkbox__all label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #383838;
}
.checkbox__all label a {
  text-decoration-line: underline;
  color: #52BA88;
}

.loan .under__title {
  max-width: 555px;
}
.loan .sub__title {
  margin-bottom: 12px;
}

.inner__loan {
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  text-align: center;
  padding: 40px 0;
  margin-top: 27px;
  border-radius: 10px;
}
.inner__loan .loan__form {
  max-width: 520px;
  margin: 0 auto;
  text-align: center;
}
.inner__loan .loan__form .inp__paragraph {
  text-align: left;
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
  margin-top: 18px;
}
.inner__loan .loan__form .btn__green {
  width: 100%;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.inner__loan .loan__form .bottom__form {
  margin-top: 15px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  padding: 0 50px;
  color: #838383;
}
.inner__loan .loan__form .form__input input {
  width: 100%;
  display: block;
  border: none;
  padding-bottom: 6px;
  border-bottom: 1px solid #D8D8D8;
  padding-top: 6px;
}
.inner__loan .loan__form .form__input input::placeholder {
  font-size: 17px;
  line-height: 23px;
  color: #383838;
}
.inner__loan .loan__form .loan__paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-top: 5px;
  padding-bottom: 22px;
  padding-left: 30px;
  padding-right: 30px;
}
.inner__loan .loan__form .loan__beforeform {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #dbece4;
  border-radius: 10px;
}
.inner__loan .loan__form .loan__beforeform span {
  margin-right: 12px;
  display: inline-block;
  min-width: 36px;
}
.inner__loan .loan__form .loan__beforeform p {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #838383;
}

.loan2__inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(207px, 1fr));
  grid-gap: 18px;
}
.loan2__inner-item {
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  padding: 18px;
  width: 100%;
}
.loan2__inner-item .loan2__image {
  display: flex;
  justify-content: center;
}
.loan2__inner-item:last-child {
  margin-right: 0;
}
.loan2__inner-item .loan2__header {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  margin-bottom: 22px;
  color: #000000;
}
.loan2__inner-item .loan2__paragraph {
  margin-top: 27px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.finhub__inner {
  display: flex;
  align-items: center;
  margin-top: 27px;
}
.finhub__inner-left {
  background: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  max-width: 538px;
  width: 100%;
  border-bottom: 4px solid #26B391;
  border-radius: 10px;
}
.finhub__inner-right {
  border-radius: 10px;
  background: #52BA88;
  padding: 25px 35px 25px 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-left: auto;
}
.finhub__inner-right .finhub__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.finhub__inner-right .finhub__item p {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
  color: #FFFFFF;
}

.tab__all {
  padding: 0;
  margin-top: 23px;
  display: flex;
  align-items: center;
}
.tab__all li {
  margin-right: 26px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.0905882px;
  list-style: none;
  color: #383838;
  cursor: pointer;
  white-space: nowrap;
}
.tab__all li.active__tab {
  color: #FFFFFF;
  padding: 5px 23px;
  background: #52BA88;
  border-radius: 18.5px;
}

.look__all a {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #52BA88;
}

.interest__card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(207px, 1fr));
  grid-gap: 25px 32px;
  margin-top: 41px;
  margin-bottom: 25px;
}
.interest__card-item {
  background: #FFFFFF;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 10px 20px 10px;
  overflow: hidden;
}
.interest__card-item .card__billet {
  width: calc(100% + 20px);
  position: relative;
  left: -10px;
  text-align: center;
  padding: 7px;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.interest__card-item .card__billet.red__billet {
  background: #D06538;
}
.interest__card-item .card__billet.blue__billet {
  background: #2A8BA0;
}
.interest__card-item .card__image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0;
  margin-bottom: 14px;
  border-bottom: 1px solid #EAEAEA;
}
.interest__card-item .card__image img {
  max-width: 140px;
  max-height: 63px;
  min-height: 63px;
  min-width: 140px;
  width: 100%;
  margin: 0 auto;
}
.interest__card-item .text__card {
  margin-top: 4px;
}
.interest__card-item .text__card h4 {
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  text-transform: uppercase;
  color: #52BA88;
  margin-bottom: 4px;
}
.interest__card-item .text__card p {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #969696;
}
.interest__card-item .text__card:first-child {
  margin-top: 4px;
}
.interest__card-item .card__btn {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 15px 0;
  width: 100%;
  display: block;
  margin-top: 23px;
}
.interest__card-item .under__btn-comission {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #383838;
  margin-top: 14px;
  text-align: center;
}

.articles__inner {
  display: grid;
  display: flex;
  margin-top: 24px;
  width: calc(100% + 24px);
  margin-left: -12px;
}
.articles__inner-item {
  background: #FFFFFF;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 10px 12px 10px;
  margin: 0 12px 25px 12px;
  overflow: hidden;
}
.articles__inner-item:last-child {
  margin-right: 0;
}
.articles__inner-item .acticles__image {
  width: calc(100% + 20px);
  position: relative;
  left: -10px;
}
.articles__inner-item .acticles__image img {
  object-fit: cover;
  height: 94px;
  width: 100%;
}
.articles__inner-item .acticles__title {
  font-weight: bold;
  font-size: 14.9837px;
  line-height: 20px;
  color: #383838;
  margin-top: 7px;
  margin-bottom: 18px;
}
.articles__inner-item .date__saw {
  display: flex;
}
.articles__inner-item .date__saw .date {
  margin-right: 15px;
  font-weight: 600;
  font-size: 10.988px;
  line-height: 15px;
  text-transform: uppercase;
  color: #969696;
}
.articles__inner-item .date__saw .saw {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 10.988px;
  line-height: 15px;
  text-transform: uppercase;
  color: #969696;
}
.articles__inner-item .date__saw .saw span {
  margin-right: 4px;
  display: flex;
}

.inner__success {
  display: flex;
}
.inner__success-left {
  margin-right: 27px;
}
.inner__success-left .image__success {
  position: relative;
}
.inner__success-left .image__success img {
  min-width: 293px;
  z-index: 1;
  width: 100%;
}
.inner__success-right {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #383838;
}
.inner__success-right p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #3D3D3D;
}
.inner__success-right .success__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 302px;
  margin: 70px 0 0;
}

.partners .loan2__inner {
  margin-bottom: 40px;
}
.partners .loan2__inner-item {
  padding-top: 26px;
}
.partners .loan2__inner-item .loan2__paragraph {
  margin-top: 12px;
  text-align: center;
}

.slick-slider .slick-arrow {
  width: auto;
  height: auto;
  z-index: 2;
  margin-top: -12.5px;
}
.slick-slider .slick-arrow:before {
  color: #52BA88;
  font-size: 45px;
}
.slick-slider .slick-arrow.slick-next {
  right: -40px;
}
.slick-slider .slick-arrow.slick-prev {
  left: -40px;
}

.inner__company {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  grid-gap: 10px;
  margin-bottom: 25px;
}
.inner__company-item {
  background: #FFFFFF;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 9px;
  text-align: center;
}
.inner__company-item .company__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.inner__company-item .company__bottom .estimate {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.inner__company-item .company__bottom .estimate span {
  font-size: 18px;
  line-height: 24.5px;
  font-weight: 600;
  color: #383838;
  margin-right: 5px;
}
.inner__company-item .company__bottom .comment {
  display: flex;
  align-items: center;
}
.inner__company-item .company__bottom .comment span {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #969696;
  margin-left: 5px;
}

.under__title p {
  margin-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}

.text {
  padding-top: 42px;
}

.dream {
  padding-top: 60px;
}

.often__search {
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer {
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.qr {
  padding-bottom: 25px;
  padding-top: 0;
  border-bottom: 1px solid #E1E1E1;
}

.depth {
  padding-top: 45px;
  padding-bottom: 35px;
}

footer {
  background: #fff;
  margin-top: 35px;
}
footer .depth__inner {
  display: flex;
  justify-content: space-between;
}
footer .depth__inner-left {
  max-width: 500px;
  width: 100%;
  margin-right: 40px;
}
footer .depth__inner-right h2 {
  font-weight: 600;
  font-size: 27px;
  line-height: 37px;
  color: #000000;
}
footer .depth__inner-right p.under__h2-depth {
  font-size: 15px;
  line-height: 20px;
  margin-top: 25px;
  color: #000000;
  margin-bottom: 0;
}
footer .depth__inner-right .contact__footer {
  display: flex;
  align-items: center;
  margin-top: 22px;
}
footer .depth__inner-right .contact__footer a {
  margin-right: 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #383838;
}
footer .depth__inner-right .contact__footer a p {
  white-space: nowrap;
}
footer .depth__inner-right .contact__footer a span {
  margin-right: 5px;
  min-width: 12px;
}
footer .depth__inner-right .contact__ul {
  margin: 0;
  margin-top: 26px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
footer .depth__inner-right .contact__ul li {
  margin-right: 23px;
}
footer .depth__inner-right .contact__ul li:last-child {
  margin-right: 0;
}
footer .depth__inner-menu ul {
  padding: 0;
  margin: 0 0 35px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
footer .depth__inner-menu ul li {
  margin-right: 30px;
}
footer .depth__inner-menu ul li a {
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  color: #383838;
}
footer .depth__inner-bottom p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
  color: #383838;
}
footer .depth__inner-bottom p:last-child {
  margin-bottom: 0;
}
footer .top__footer h5 {
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  color: #383838;
}
footer .top__footer-menu {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
footer .top__footer-menu ul {
  margin: 0;
  padding: 0;
}
footer .top__footer-menu ul li {
  list-style: none;
  margin-bottom: 15px;
}
footer .top__footer-menu ul li:last-child {
  margin-bottom: 0;
}
footer .top__footer-menu ul li a {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #383838;
}
footer .footer__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .footer__main-social ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
footer .footer__main-social ul li {
  margin-right: 22px;
  list-style: none;
}
footer .footer__main-social ul li:last-child {
  margin-right: 0;
}
footer .inner__qr {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
footer .inner__qr .qr__right__image {
  position: relative;
  bottom: -25px;
  display: flex;
}
footer .inner__qr .qr__block .qr__top {
  display: flex;
}
footer .inner__qr .qr__block .qr__top-text {
  margin-left: 25px;
}
footer .inner__qr .qr__block .qr__top-text .header__qr {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
footer .inner__qr .qr__block .qr__top-text .subheader__qr {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-top: 15px;
}
footer .inner__qr .qr__bottom {
  margin-top: 33px;
}
footer .inner__qr .qr__bottom ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
footer .inner__qr .qr__bottom ul li {
  margin-right: 21px;
}
footer .inner__qr .qr__bottom ul li:last-child {
  margin-right: 0;
}

.micro {
  background: #FFFFFF;
  box-shadow: 0px 2px 12px #ECF0F5;
  padding-top: 36px;
  padding-bottom: 24px;
}
.micro h1.main__title {
  text-transform: inherit;
  text-align: center;
}
.micro p.under__title {
  max-width: 917px;
  width: 100%;
  margin: 0 auto;
  margin-top: 36px;
}

.client__fin {
  padding-top: 36px;
}

.mobile__nav-open {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  background: #fff;
}
.mobile__nav-open .btn__enter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.mobile__nav-open .btn__enter a {
  border-radius: 5px;
  color: #69AC8C;
  text-decoration: none;
  width: 100%;
  height: 45px;
  font-size: 15px;
  font-weight: 600;
  background: #D9FEEC;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile__nav-open .btn__credit a {
  width: 100%;
}
.mobile__nav-open .phone__mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  margin-top: 30px;
  color: #383838;
}
.mobile__nav-open .phone__mobile span {
  margin-right: 4px;
}
.mobile__nav-open .logotype {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}
.mobile__nav-open .navbar__mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}
.mobile__nav-open .navbar__mobile ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
}
.mobile__nav-open .navbar__mobile ul li {
  margin-bottom: 26px;
  text-align: left;
}
.mobile__nav-open .navbar__mobile ul li:last-child {
  margin-bottom: 0;
}
.mobile__nav-open .navbar__mobile ul li a {
  font-size: 16px;
  color: #989898;
  font-weight: 400;
}
.mobile__nav-open .navbar__mobile ul li a:hover {
  color: #171717;
}
.mobile__nav-open .close__icon {
  position: absolute;
  right: 0;
  top: 0;
}
.mobile__nav-open .title__popup {
  text-align: center;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}

.mobile__nav-open {
  display: none;
}

.anketa__full-block {
  background: #FFFFFF;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
}

.inner__anketa {
  text-align: center;
}
.inner__anketa .anketa__credit {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.country__p {
  color: #686868;
}

.country {
  font-weight: bold;
}

.inner__form-anketa {
  margin-top: 53px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: 0 80px;
}
.inner__form-anketa .form__input {
  margin-bottom: 24px;
}
.inner__form-anketa .form__input p {
  font-size: 11px;
  line-height: 15px;
  color: #838383;
}
.inner__form-anketa .form__input input {
  width: 100%;
  border: none;
  background: transparent;
  padding-bottom: 6px;
  padding-top: 6px;
  margin-top: 5px;
  border-bottom: 1px solid #D8D8D8;
}
.inner__form-anketa .form__input input::placeholder {
  color: #C1C1C1;
}
.inner__form-anketa .form__input input:focus {
  outline: none;
}
.inner__form-anketa .form__input select {
  width: 100%;
  border: none;
  background: transparent;
  padding-top: 6px;
  margin-top: 5px;
  border-bottom: 1px solid #D8D8D8;
  color: #C1C1C1;
}

.btn__bottom-anketa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  flex-wrap: wrap;
}
.btn__bottom-anketa .btn__credit {
  margin: 0;
}
.btn__bottom-anketa .right__bottom {
  display: flex;
}
.btn__bottom-anketa .right__bottom .item__anketa-bottom {
  margin-right: 35px;
}
.btn__bottom-anketa .right__bottom .item__anketa-bottom p:first-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #979797;
}
.btn__bottom-anketa .right__bottom .item__anketa-bottom p:last-child {
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: #141E44;
}
.btn__bottom-anketa .right__bottom .item__anketa-bottom:last-child {
  margin-right: 0;
}

.anketa__under {
  background: #D9FEEC;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  margin-top: 31px;
  color: #838383;
}
.anketa__under span {
  margin-right: 15px;
  max-width: 37px;
  min-width: 37px;
}

.inner__service {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-gap: 15px 15px;
}
.inner__service-item {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.inner__service-item p {
  font-size: 14px;
  line-height: 19px;
  width: 50%;
}
.inner__service-item span {
  font-size: 80px;
  line-height: 109px;
  text-transform: uppercase;
  color: #52BA88;
  position: relative;
}
.inner__service-item span:before {
  content: "";
  position: absolute;
  top: -22px;
  border: 20px solid #D2FAE7;
  box-sizing: border-box;
  width: 164px;
  height: 164px;
  right: -60px;
  border-radius: 50%;
}

.text-center {
  text-align: center;
}

.inner__operator {
  border: 2px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 15px;
}
.inner__operator p {
  line-height: 30px;
}

.question__p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}
.question__p:first-child {
  margin-top: 40px;
}

.b-faq__inner {
  margin-bottom: 40px;
}

.b-faq {
  margin: 5px auto;
}
.b-faq .faq__item {
  margin-bottom: 15px;
}

.b-faq .faq__title {
  position: relative;
  display: block;
  padding: 25px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  color: #202020;
  font-size: 20px;
  background: #fff;
  border-radius: 10px;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.b-faq .faq__title .faq__spoiler {
  position: absolute;
  top: 18px;
  right: 20px;
  color: #fff;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.b-faq .faq__rotate {
  transform: rotate(180deg);
}

.b-faq .faq__content {
  padding: 30px;
  margin-bottom: 2px;
  font-size: 14px;
  display: none;
  background-color: #fff;
}

.b-faq .faq__symbol {
  font-weight: 700;
}

.banner {
  padding-top: 20px;
  padding-bottom: 20px;
}

.inner__banner {
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 7px 10px 0;
  border-radius: 10px;
}

.flex-banner-right {
  display: flex;
}

.inner__banner-container {
  max-width: 732px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner__banner-container h1.title__banner {
  font-size: 27px;
  line-height: 37px;
}
.inner__banner-container .flex__subtitle {
  display: flex;
  align-items: flex-end;
}
.inner__banner-container .subtitle__banner {
  color: #5E5E5E;
  margin-top: 15px;
  margin-right: 20px;
}
.inner__banner-container .subtitle__banner span {
  font-size: 24px;
  color: #52BA88;
}
.inner__banner-container .odobreno p {
  font-size: 13px;
  text-align: center;
}
.inner__banner-container .odobreno .analog__btn {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-top: 5px;
  background: #CF2557;
  border-radius: 200px;
}
.inner__banner-container .odobreno .analog__btn img {
  margin-right: 10px;
}
.inner__banner-container .odobreno .analog__btn span.text__weight {
  font-weight: 600;
  margin-right: 10px;
  font-size: 15px;
  color: #fff;
}
.inner__banner-container .odobreno .text__near-weight {
  color: #fff;
  font-size: 13px;
}

.showcase__inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  grid-gap: 31px;
}
.showcase__inner-item {
  background: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 0 10px 10px 10px;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}
.showcase__inner-item .top__showcase {
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 7px;
  background: #D06538;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */
  text-transform: uppercase;
  color: #FFFFFF;
}
.showcase__inner-item .image__showcase {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.showcase__inner-item .image__showcase img {
  max-width: 171px;
  width: 100%;
}
.showcase__inner-item .showcase__info .flex__info {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 17px;
  line-height: 16px;
}
.showcase__inner-item .showcase__info .flex__info .left__info {
  color: #274E5A;
}
.showcase__inner-item .showcase__info .flex__info .right__info {
  color: #274E5A;
  font-weight: bold;
}
.showcase__inner-item .btn__credit {
  display: flex;
  flex-direction: column;
}
.showcase__inner-item .btn__credit a {
  width: 100%;
}
.showcase__inner-item .btn__credit p {
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #141E44;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
}

.range-block {
  box-sizing: border-box;
  margin-bottom: 24px;
}
.range-block .range__inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.range-block label {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.range-block label input {
  width: 100%;
}

.range-block label input[type=range] {
  -webkit-appearance: none;
  height: 12px;
  padding: 0;
  border-radius: 200px;
}

.range-block label input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: content-box;
  background: #FFFFFF;
  border: 3px solid #52BA88;
  box-sizing: border-box;
  border-radius: 50%;
  height: 24px;
  width: 24px;
}

.range-block label #range {
  background: -webkit-linear-gradient(left, #52BA88, #52BA88 20%, #D9FEEC 0, #D9FEEC);
}

.range-block button {
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid #002b5d;
  box-sizing: border-box;
  text-align: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.range-block button span {
  min-width: 10px;
  min-height: 2px;
  background: #002b5d;
  border-radius: 2px;
}

.burger__menu {
  display: none;
}

.close__icon {
  display: none;
}

@media (max-width: 1200px) {
  .slick-slider .slick-arrow {
    left: inherit;
    right: inherit;
  }
  .slick-slider .slick-arrow.slick-next {
    right: 5px;
  }
  .slick-slider .slick-arrow.slick-prev {
    left: 5px;
  }
}
@media (max-width: 992px) {
  .finhub__inner-left {
    position: inherit;
  }

  .finhub__inner-right {
    padding: 25px 35px;
  }

  .finhub__inner {
    flex-direction: column;
  }

  .finhub__inner-right {
    margin-left: 0;
  }

  .footer__main .logotype {
    margin-left: 0;
  }

  .main__inner__left .coef .bottom__coef__name .gift__from__team {
    display: none;
  }

  .phone__number {
    display: none;
  }

  .burger__menu {
    display: inline-block;
    cursor: pointer;
  }

  .header__inner .right__header .phone__number {
    display: none;
  }

  .logotype {
    margin-left: 20px;
  }

  .header__inner .left__header .navbar {
    position: absolute;
    top: 0;
    background: #fff;
    width: 100%;
    left: 0;
    right: 0;
    padding-top: 20px;
    z-index: 2;
    display: none;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
  }
  .header__inner .left__header .navbar ul {
    flex-direction: column;
    padding-left: 0;
    text-align: center;
    padding-top: 40px;
  }
  .header__inner .left__header .navbar ul li {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .header__inner .left__header .navbar ul li a {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .cash-offer {
    margin-top: 200px;
  }

  .faq__text {
    font-size: 16px;
  }

  .b-faq .faq__title {
    padding-top: 15px;
    padding-bottom: 20px;
  }

  .b-faq .faq__title .faq__spoiler {
    top: 14px;
  }

  .inner__form-anketa {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .btn__bottom-anketa .right__bottom {
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .close__icon {
    display: inline-block;
  }

  .interest__card-item .under__btn-comission {
    font-size: 12px;
    line-height: 16px;
  }

  .inner__loan .loan__form .bottom__form {
    padding-left: 0;
    padding-right: 0;
  }

  .inner__loan .loan__form .loan__paragraph {
    padding-left: 0;
    padding-right: 0;
    font-size: 13px;
  }

  .under__title p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }

  .finhub__inner-left {
    font-size: 16px;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
  }

  .inner__loan {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .loan {
    padding-bottom: 25px;
  }

  .exchange__inner-item span {
    font-size: 13px;
    margin-right: 7px;
  }

  .exchange__inner {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    grid-gap: 5px;
  }

  .exchange__inner-item {
    display: flex;
  }

  .exchange__inner-item .arrow {
    display: flex;
    align-items: center;
  }

  .finhub__inner {
    margin-top: 0;
  }

  .loan2__inner {
    margin-top: 20px;
  }

  .exchange__inner {
    margin-top: 20px;
  }

  .finhub__inner-right {
    margin-top: 20px;
  }

  .finhub__inner-right {
    grid-gap: 30px 40px;
    width: 100%;
  }

  .tab__all {
    overflow-x: auto;
    height: 45px;
    padding-bottom: 10px;
    margin-bottom: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-left: 20px;
  }

  .btn__credit {
    margin: 19px auto 0;
  }

  .articles__inner {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .image__success {
    max-width: 220px;
    margin: 0 auto;
  }

  .inner__success-left {
    margin-right: 0;
  }

  .inner__success-left .image__success:before {
    width: 227.84px;
    height: 209.3px;
  }

  .inner__success-left .image__success img {
    max-width: 220px;
    min-width: auto;
    width: 100%;
    margin: 0 auto;
  }

  .inner__success-right {
    margin-top: 40px;
  }
  .inner__company {
    display: flex;
    overflow-x: auto;
    padding: 20px;
    margin-bottom: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .text {
    padding-top: 30px;
  }

  .inner__company-item {
    min-width: 130px;
  }

  .inner__success-right .success__btn {
    margin-top: 20px;
  }

  .under__title {
    font-size: 12px;
    line-height: 16px;
  }

  .sub__title {
    margin-bottom: 25px;
  }

  .interest__card {
    margin-top: 13px;
  }

  .inner__success {
    flex-direction: column;
  }

  footer .inner__qr .qr__right__image {
    margin-left: 20px;
  }

  footer .inner__qr .qr__block .qr__top {
    flex-direction: column;
  }

  footer .inner__qr .qr__block .qr__top-text {
    margin-left: 0;
  }

  footer .inner__qr .qr__right__image img {
    min-width: 276px;
    height: 179px;
  }

  .main__title {
    font-size: 30px;
    line-height: 37px;
    color: #383838;
  }

  .selection__item .image__parag p {
    font-size: 14px;
  }

  .selection__item .image__parag p span {
    font-size: 19px;
    margin-bottom: 0px;
  }

  section.main {
    padding-top: 32px;
  }

  .btn__credit a {
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
  }

  .selection__image {
    right: -40px;
  }
  .selection__image img {
    width: 150px;
  }
  .selection__image:before {
    width: 162px;
    height: 162px;
  }

  .selection__item {
    padding: 12px 17px;
    height: auto;
    margin-bottom: 16px;
  }

  .sub__title {
    font-size: 20px;
    line-height: 26px;
  }

  .select__btn {
    margin-top: 60px;
  }

  section {
    padding-top: 42px;
  }

  .selection {
    grid-template-columns: 1fr;
  }

  .logotype {
    margin-left: 20px;
  }
  .logotype img {
    width: 91px;
  }

  footer .top__footer-menu ul {
    margin-bottom: 25px;
  }
  footer .top__footer-menu ul:last-child {
    margin-bottom: 0;
  }

  footer .top__footer-menu ul li {
    margin-bottom: 10px;
  }

  footer .inner__qr .qr__block .qr__top {
    display: none;
  }

  .qr__block {
    width: 100%;
  }

  footer .inner__qr .qr__bottom {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  footer .inner__qr .qr__bottom ul {
    flex-direction: column;
    justify-content: center;
  }

  footer .inner__qr .qr__bottom ul li {
    margin-right: 0;
  }
}
@media (max-width: 576px) {
  .articles__inner {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .inner__banner-container h1.title__banner {
    font-size: 20px;
    line-height: 25px;
  }

  .inner__banner-container .flex__subtitle {
    flex-direction: column;
    align-items: flex-start;
  }

  .inner__banner-container .subtitle__banner {
    margin-bottom: 20px;
    font-size: 13px;
    width: 120px;
  }
  .inner__banner-container .subtitle__banner span {
    font-size: 16px;
  }

  .inner__banner-container {
    position: relative;
    padding-bottom: 20px;
  }
  .inner__banner-container .flex-banner-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  footer .depth__inner-right .contact__footer {
    flex-direction: column;
  }

  .depth__inner-right {
    text-align: center;
    margin-bottom: 20px;
  }

  footer .depth__inner-menu ul li {
    margin-right: 17px;
  }

  footer .depth__inner-right .contact__ul {
    justify-content: center;
  }

  footer .depth__inner-right .contact__footer a {
    margin-right: 0;
    margin-bottom: 10px;
  }
  footer .depth__inner-right .contact__footer a:last-child {
    margin-bottom: 0;
  }

  .btn__bottom-anketa .btn__credit {
    width: 100%;
  }

  .btn__bottom-anketa .btn__credit a {
    width: 100%;
  }

  footer .footer__main {
    flex-direction: column;
  }
  footer .footer__main .logotype {
    margin-bottom: 25px;
  }
  footer .footer__main .logotype img {
    width: auto;
  }

  .articles__inner-item {
    width: 300px;
  }

  footer .inner__qr .qr__right__image {
    display: none;
  }

  footer .depth__inner {
    flex-direction: column-reverse;
  }

  .depth {
    padding-top: 20px;
  }

  footer .top__footer-menu {
    flex-direction: column;
  }

  .inner__banner-container .odobreno .analog__btn span.text__weight {
    margin-right: 5px;
    font-size: 13px;
  }

  .inner__banner-container .odobreno .analog__btn span.text__near-weight {
    font-size: 11px;
  }

  .exchange__inner-item .arrow {
    margin-right: 0;
  }

  .exchange__inner {
    padding: 20px 14px;
  }
}
@media (max-width: 500px) {
  .btn__bottom-anketa .right__bottom .item__anketa-bottom {
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .exchange__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .finhub__inner-right {
    grid-gap: 30px 40px;
    grid-template-columns: 1fr;
  }

  .inner__banner-container .flex-banner-right img {
    width: 190px;
  }
}