html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "asd", sans-serif;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; // stylelint-disable-line declaration-no-important
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
